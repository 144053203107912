'use client'
import Menu from '@/core/modules/menu'

export const Loader = () => {
  // Loader component
  return (
    <div className="loadingcontent">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

const Loading = () => {
  return (
    <>
      <Menu loadingVariant={true} noLinks={true} />

      <div style={{ padding: '100px 0' }}>
        <Loader />
      </div>
    </>
  )
}
export default Loading
